import React from "react"
import { graphql } from "gatsby"
import Layout from "src/components/Layout"
import SEO from "src/components/seo"
import PageHeading from "src/components/PageHeading"
import LazyLoadImage from "src/components/LazyLoadImage"
import "./index.less"

const prettyDate = releaseDate => {
  const year = releaseDate.slice(0, 4)
  const monthNumber = Number(releaseDate.slice(5, 7))
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ][monthNumber - 1]
  const day = releaseDate.slice(8)

  return `${month} ${day}, ${year}`
}

const stockXLink = title => {
  const cleanedTitle = title.replace(/®|\/|\(|\)|Supreme/g, "")
  const encodedTitle = encodeURIComponent(`Supreme ${cleanedTitle}`)

  return `https://stockx.pvxt.net/c/1390259/530344/9060?u=https://stockx.com/search%3Fs%3D${encodedTitle}`
}

const Item = ({ data }) => {
  const item = data.item.edges[0].node

  const {
    title,
    description,
    price,
    releaseDate,
    colorways,
    sizingTable,
  } = item

  const prettyReleaseDate = prettyDate(releaseDate)
  const affiliateLink = stockXLink(title)

  return (
    <Layout pageName="item">
      <SEO
        title={title}
        description={description}
        image={`/images/${colorways[0].images[0].src}`}
      />

      <PageHeading>{title}</PageHeading>

      <div className="item-details">
        <p>{prettyReleaseDate}</p>
        <p>{description}</p>
        <p>{price}</p>

        <div className="stockx-link-container">
          <a href={affiliateLink} target="_blank" rel="noopener noreferrer">
            Buy here
          </a>
        </div>

        {sizingTable && (
          <div className="item-details__table-container">
            <table dangerouslySetInnerHTML={{ __html: sizingTable }} />
          </div>
        )}
      </div>

      <div className="item-colorways">
        {colorways.map(colorway => (
          <div key={colorway.canonical} className="item-colorways__colorway">
            <h2>{colorway.title}</h2>
            <div className="item-colorways__images">
              {colorway.images.map((image, imageIndex) => {
                return (
                  image.src && (
                    <LazyLoadImage
                      key={`${colorway.canonical}-${imageIndex}`}
                      wrapperClassName="item-colorways__image"
                      src={`/images/${image.src}`}
                      alt={`${colorway.title} ${imageIndex}`}
                      width={300}
                    />
                  )
                )
              })}
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default Item

export const query = graphql`
  query ItemQuery($season: String!, $category: String!, $item: String!) {
    item: allItemsJson(
      filter: {
        season: { eq: $season }
        category: { eq: $category }
        canonical: { eq: $item }
      }
    ) {
      edges {
        node {
          title
          sizingTable
          releaseDate
          price
          description
          category
          canonical
          colorways {
            canonical
            title
            images {
              src
            }
          }
        }
      }
    }
  }
`
